import React from "react";
import Layout from "../components/layout"
import SEO from "../components/seo"
import SecondaryHeader from "../components/secondary-header";
import ComingSoonProgress from "../images/svg/progres_coming_soon.svg";

const ComingSoon = () => (
    <Layout>
        <SEO title="Coming Soon" />

        <SecondaryHeader/>

        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', margin: '10% 0'}}>
            <h2 style={{fontSize: '265%'}}>We’ve been busy.</h2>
            <h2 style={{fontSize: '110%', color: '#6E6F71'}}>Sharpen your ears, we launch soon!</h2>
            <ComingSoonProgress style={{width: 170}}/>
        </div>

    </Layout>
);

export default ComingSoon;